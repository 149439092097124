import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';
import QRCodeGenerator from '../components/QRCodeGenerator';

function Post() {
    let { id } = useParams();
    const [post, setPost] = useState(null);

    useEffect(() => {
        axios.get(`http://localhost:3001/posts/byId/${id}`)
            .then(response => {
                setPost(response.data); 
            })
            .catch(error => {
                console.error('Error fetching post:', error);
            }); 
    }, [id]); 

    return (
        <div>
            {post ? (
                <div>
                    <h2>{post.username}</h2>
                    <p>{post.email}</p>
                    <p>{post.postText}</p>
                    <QRCodeGenerator value={post.postText} />
                </div>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
}

export default Post;
