import './App.css';
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Home from './pages/Home';
import CreateTiko from './pages/CreateTiko';
import Post from './pages/Post';

function App() {

  return (
    <div className="App">
      <Router>
        <Link to="/">Home</Link> 
        <Link to="/createtiko">Create Ticket</Link>
        <Routes>
          <Route path='/' element={<Home />} /> 
          <Route path='/createtiko' element={<CreateTiko />} /> 
          <Route path='/post/:id' element={<Post />} /> 
        </Routes> 
      </Router>
    </div>
  );
}

export default App;
