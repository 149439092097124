import React from 'react';
import QRCode from 'qrcode.react';

function QRCodeGenerator({ value }) {
    return (
        <div>
            <h3>QR Code</h3>
            <QRCode value={value} />
        </div>
    );
}

export default QRCodeGenerator;
