import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Home() {
    const [listOfPosts, setListOfPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate(); 

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get('http://localhost:3001/posts');
                setListOfPosts(response.data);
            } catch (error) {
                setError('An error occurred while fetching posts.');
            } finally {
                setLoading(false);
            }
        };

        fetchPosts();
    }, []);

    return (
        <div className="container">
            {loading ? (
                <div>Loading...</div>
            ) : error ? (
                <div>{error}</div>
            ) : (
                <div>
                    {listOfPosts.map((value, key) => (
                        <div className="main" key={value.id} onClick={() => navigate(`/post/${value.id}`)}>
                            <div className="post">{value.postText}</div>
                            <div className="user">{value.username}</div>
                            <div className="email">{value.email}</div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default Home;
