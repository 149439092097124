import React, { useState, useEffect } from 'react';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


function CreateTiko() {
    const initialValues = {
        username: '',
        email: '',
    };

    const validationSchema = Yup.object().shape({
        username: Yup.string().min(3).max(15).required(),
        email: Yup.string().email().required(),
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [randomCode, setRandomCode] = useState('');

    useEffect(() => {
        const generateRandomCode = () => {
            const randomNumber = Math.floor(Math.random() * 999).toString().padStart(3, '0');
            const randomAlphabets = generateRandomAlphabets(2);
            return `DKN${randomNumber}${randomAlphabets}`;
        };

        setRandomCode(generateRandomCode());
    }, []);

    const generateRandomAlphabets = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };

    const navigate = useNavigate();

    const onSubmit = async (data, { resetForm }) => {
        setIsSubmitting(true);
        try {
            await axios.post('http://localhost:3001/posts', { ...data, postText: randomCode });
            alert('Tiko created successfully!');
            resetForm();
            navigate('/');
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="createTiko">
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                <Form>
                    <div className="form-group">
                        <ErrorMessage name="username" component="div" className="error-message" />
                        <Field name="username" placeholder="username" className="form-control" />
                    </div>

                    <div className="form-group">
                        <ErrorMessage name="email" component="div" className="error-message" />
                        <Field name="email" type="email" placeholder="Email" className="form-control" />
                    </div>

                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? 'Creating Tiko...' : 'Create Tiko'}
                    </button>
                </Form>
            </Formik>
        </div>
    );
}

export default CreateTiko;
